#logo-nav-link, #logo-nav-div{
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

#topbar{
  background-color: #634632;

  > div{
    max-width: 50rem;
    margin-left:auto;
    margin-right: auto;
  }

  a {
    cursor: pointer;
  }
}

.navbar-brand{
  img{
    width:100%;

    @media screen and (min-width: 500px) {
      width:400px;
    }
  }

  width:100%;

  @media screen and (min-width: 500px) {
    width:auto;
  }
}

.navbar-toggler{
  margin:1rem auto;
}

.navbar-dark .navbar-nav .nav-link {
  color: #f9e1b5;
}