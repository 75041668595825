@import './utilities/variables';

#company-name{
  background: #634632;
  padding: 1rem;
  color: #f9e1b5;
}

#carousel{
  max-width: 100rem;
  margin: 0 auto;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

#name{
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 1.15rem;
}

section{
  border-bottom: 2px #634632 solid;
}

#tagline{
  h4{
    padding: 1rem 3rem;
  }

  p{
    padding: 1rem 3rem;
  }
}

#services{
  margin: 1rem 0rem 1rem 0rem;

  h1{
    margin-bottom: 1rem;
  }

  div.col{
    margin: 0rem 1rem;
  }

  li{
    padding-top: .2rem;
  }

  @media screen and (min-width: $small-bp) {
    div.col{
      margin: 0rem 0.75rem;
    }
  }

  @media screen and (min-width: $medium-bp) {
    div.col{
      margin: 0;
    }
  }
}

ul{
  list-style-type: none;
  padding-left: 0;
}

.service-title{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 2rem;
  font-weight: bold;
}

#contact-us{
  margin: 3rem 2rem;
  padding: 1rem;
  color: #f9e1b5;
  background: #634632;
  border-radius: 25px;

  a{
    color: #f9e1b5;
  }
}

#servicemap{
  img{
    width:100%;
    margin: 0px auto;
    display: block;

    max-width: 50rem;
  }

  margin: 1rem 0rem 2rem 0rem;
}

footer{
  margin-bottom: 2rem;
}